.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  background-color: #e7f3f9;
}

.blue600 {
  color: #00537d;
}

.bgBlue600{
  background-color: #00537d;
}

.blue500 {
  color: #007dbc;
}

.bgBlue500 {
  background-color: #007dbc;
}

.bgBlue100 {
  background-color: #e7f3f9;
}

.homeScreenInfo {
  background-color: white;
  border-radius: 8px;
  width: 8rem;
  height: 8rem;
  margin: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 1.2rem;
  justify-content: center;
  white-space: nowrap;
  color: #00537d;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.homeScreenBg {
  position: absolute;
  width: 100%;
  height: 75%;
  bottom: 0;
  background-color: #e7f3f9;
  z-index: 5;
}

.homeScreenAddNewItem {
  position: absolute;
  bottom: 40px;
  align-self: center;
  padding: 10px 10px;
  background-color: white;
  color: #00537d;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 4px;
  font-weight: 700;
}

.approvalCard > label {
  display: flex;
  justify-content: space-between;
}

.approvalCard > label > input {
  border: 1px solid #8996a2;
  border-radius: 4px;
  font-weight: 700;
  font-size: 14px;
}

.approvalCloseInput {
  border: 1px solid #8996a2 !important;
}

.approvedButton {
  background-color: #007dbc;
  color: #fff;
  padding: 8px;
  border-radius: 4px;
  width: 40%;
  font-weight: 700;
}

.approvedPopUp {
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: 10;
  left: 0px;
  top: 0px;
  justify-content: center;
  align-items: center;
  background-color: black;
  opacity: 0.6;
}

.grey400 {
  color: #8c96a2;
}

.addProductInput {
  text-align: -webkit-center;
}

.addProductStickyButtons {
  align-self: center;
  padding: 10px 10px;
  background-color: #007dbc;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 4px;
  font-weight: 700;
}

.addProductStickyButtonsBg {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  height: 10%;
  display: flex;
  justify-content: center;
}

.priceTag {
  align-self: center;
  font-weight: 700;
  border-radius: 4px;
  background-color: #ffea28;
}

.dropdown {
  margin: 0 auto;
  user-select: none;
  position: relative;
  height: 100% !important;
}

.dropdown .dropdown-btn {
  padding: 10px;
  background-color: #fff;
  box-shadow: 3px 3px 10px 6px rgb(0, 0, 0, 0.06);
  font-weight: 700;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.dropdown .dropdown-content {
  top: 110%;
  left: 0;
  padding: 0px 10px;
  background-color: #fff;
  box-shadow: 3px 3px 10px 6px rgb(0, 0, 0, 0.06);
  font-weight: 500;
  color: #333;
  width: 100%;
  margin-bottom: 100px;
}

.dropdown .dropdown-content .dropdown-item {
  padding: 10px;
  cursor: pointer;
  transition: all 0.2s;
}

.dropdown .dropdown-content .dropdown-item:hover {
  background: #f4f4f4;
}

.priceInput {
  box-shadow: 3px 3px 10px 6px rgb(0, 0, 0, 0.06);
}

.scanError {
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 0;
  background-color: #000;
  opacity: 0.4;
  z-index: 4;
}

.printPopUp {
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  background-color: #000;
  opacity: 0.4;
  z-index: 10;
}


input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 26px;
  height: 26px;
  border: 1px solid #00537d;
  border-radius: 50%;
  outline: none;
  transition: border-color 0.3s ease-in-out;
  position: relative;
}

input[type="checkbox"]::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 14px;
  height: 14px;
  background-color: #00537d;
  border-radius: 50%;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

input[type="checkbox"]:checked::before {
  opacity: 1;
}

.homePageIcon {
  position: absolute;
  bottom: 60px;
  z-index: -1;
  right: 0;
}

.containerLoading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  position: absolute;
  top: 0;
  z-index: 20;
  background: white;
  width: 100%;
}

.svgLoading {
  background: none;
  display: block;
  shape-rendering: auto;
  width: 130px;
  height: 130px;
}

.selectList{
  margin: auto;
}

.rec.rec-arrow {
  box-shadow: none !important;
  background-color: white !important;
  height: 100%;
  color: #000 !important;
  border-radius: 0% !important;
  min-width: 10px;
  width: 10px;
}

.rec-arrow-right{
  position: absolute;
  right: -15px;
  z-index: 9;
}

.rec-arrow-left{
  position: absolute;
  left: -15px;
  z-index: 9;
}

.photoGrid{
  display: grid;
  grid-template-columns: repeat(3, 2fr);
  grid-template-rows: repeat(2);
}

.file-input__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input__label {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  width: 100%;
  height: 60px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  font-size: 14px;
  padding: 10px 12px;
  justify-content: center;
}

.file-input__label svg {
  height: 32px;
  margin-right: 4px;
}

.ReactModal__Overlay--after-open{
  z-index: 12;
  padding: 3rem 0.5rem 0 0.5rem;
}

.replace-button::-webkit-file-upload-button{
  visibility: hidden;
}

.fileUpload {
	overflow: hidden;
	padding: 0.875em;/*14px/16px*/
	position: relative;
	text-align: center;
	width: 120px;
   cursor: pointer;
}

.fileUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    color: transparent;
    cursor: pointer;
}

.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

.writing-vertical{
  writing-mode: vertical-rl;
}

.MuiAccordionSummary-content {
  display: flex;
  flex-direction: column;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  border-radius: 4px;
}

/* .MuiPaper-root::before{
  transition: margin 3000ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
}
.MuiPaper-root{
  transition: margin 3000ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
} */
@media screen and (min-width: 855px) {
  .homeScreenBg {
    height: 70%;
  }

  .cameraHide{
    display: none;
  }
  
  .homePageIcon {
    display: none;
  }

  .menu {
    justify-content: space-between !important;
    width: 70%;
    align-self: center;
  }

  .homeScreenInfo {
    margin: 0;
    padding: 5rem 5.2rem;
  }

  .loginButton {
    width: 20% !important;
  }
  .sideBar {
    width: 20% !important;
  }

  .productCard {
    width: 30% !important;
  }

  .homeScreenAddNewItem {
    font-size: 20px;
    padding: 15px 10px;
    width: 70% !important;
  }

  .approvedPopUpScreen {
    width: 30% !important;
    height: 45% !important;
    justify-content: space-evenly !important;
  }

  .printPopUpParent {
    width: 30% !important;
  }

  .addProductStickyButtons {
    width: 25% !important;
  }

  .productDetails {
    width: 25% !important;
  }

  .selectList{
    margin: 0;
  }

  .ReactModal__Overlay--after-open{
    z-index: 10;
    padding-top: 50px;
  }

  .photoListing{
    margin: 0% 35%;
  }
}